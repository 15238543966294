import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { UserModel } from '../models/user.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { jwtDecode } from 'jwt-decode';
import { DecodeToken } from '../models/token/deoced-token.model';
import { ApplicationService } from './application.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseUrl = environment.apiUrl;
  menuList!: any[];
  routeToMenuMap: { [key: string]: number } = {};
  private tokenValidated = false; // Flag to track token validation

  private _localUser: UserModel | null = null;
  public get localUser(): UserModel | null {
    return this._localUser;
  }
  public set localUser(v: UserModel) {
    this._localUser = v;
  }

  constructor(private http: HttpClient) {
    if (
      localStorage.getItem('auth_app_user') !== null &&
      localStorage.getItem('auth_app_user') !== undefined
    ) {
      this.localUser = JSON.parse(localStorage.getItem('auth_app_user')!);
    }

    // Fetch menu data on service initialization
    // this.getAllMenus().subscribe(
    //   (menuData) => {
    //     this.menuList = menuData;
    //     console.log('Fetched Menu List:', this.menuList); // Logs the fetched menu data

    //     this.routeToMenuMap = this.buildRouteToMenuMap(menuData);
    //     //log('Route to Menu Map:', this.routeToMenuMap); // Logs the generated routeToMenuMap
    //   },
    //   (error) => {
    //     console.error('Error fetching menu data:', error); // Handles any errors during API call
    //   }
    // );
  }

  logIn(userModel: UserModel): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/api/User/Login/`, userModel)
      .pipe(map((response) => response));
  }

  isAuthenticated(): Observable<boolean> {
    return of(
      localStorage.getItem('auth_app_token') !== null &&
        localStorage.getItem('auth_app_user') !== null
    );
  }

  setLocalUser(jwtToken: string): void {
    // Decode the JWT token
    // localStorage.removeItem('auth_app_token');
    localStorage.setItem('auth_app_token', jwtToken);

    // let decoded = this.decodeToken(jwtToken);
    let decoded: any = jwtDecode(jwtToken);
    // console.log('DECODED TOKEN ============> ', decoded);
    let entityId = Number.parseInt(decoded.entityId);
    let userId = Number.parseInt(decoded.userId);
    let employeeId = Number.parseInt(decoded.employeeId);
    let isOwner = decoded.IsOwner == 'False' ? false : true;

    let outletIds: number[] = [];
    if (decoded.outletIds != undefined && decoded.outletIds != null) {
      outletIds = decoded.outletIds
        .split(',')
        .map((a: any) => Number(a.trim()));
    }

    let menuIds: number[] = [];
    if (decoded.menuIds != undefined && decoded.menuIds != null) {
      menuIds = decoded.menuIds.split(',').map((a: any) => Number(a.trim()));
    }
    // Log the payload data
    this._localUser = {
      userId: userId,
      password: '',
      firstName: decoded.firstName,
      lastName: decoded.lastName,
      userName: decoded.unique_name,
      countryId: 0,
      phoneNumber: '',
      email: '',
      countryCode: '',
      entityIds: entityId,
      profPic: `${this.baseUrl}/images/${decoded.entityLogoUrl}`,
      outletIds: outletIds,
      employeeId: employeeId,
      isOwner: isOwner,
      menuIds: menuIds,
      //userRoles, roleNames
    };

    localStorage.setItem('auth_app_user', JSON.stringify(this.localUser));
  }

  // buildRouteToMenuMap(menuList: any[]): { [key: string]: number } {
  //   const routeToMenuMap: { [key: string]: number } = {};

  //   // Map menu names (converted to lowercase or key-friendly strings) to menu IDs
  //   menuList.forEach((menuItem) => {
  //     if (menuItem.parentMenuName) {
  //       const menuName = menuItem.parentMenuName
  //         .replace(/\s+/g, '')
  //         .toLowerCase(); // Remove spaces and convert to lowercase
  //       routeToMenuMap[menuName] = menuItem.parentMenuId;

  //       // Log each mapping to ensure correctness
  //       console.log(`Mapped ${menuName} to ID ${menuItem.parentMenuId}`);
  //     }
  //   });

  //   return routeToMenuMap;
  // }

  // hasAccessToRoute(routePath: string | undefined): boolean {
  //   if (!routePath || !this.localUser) {
  //     return false;
  //   }

  //   // const routeToMenuMap: { [key: string]: number } = {
  //   //   branch: 2,
  //   //   addons: 9,
  //   // };
  //   // const requiredMenuId = routeToMenuMap[routePath];
  //   const requiredMenuId =
  //     this.routeToMenuMap[routePath.replace(/\s+/g, '').toLowerCase()];

  //   if (!requiredMenuId) {
  //     return false;
  //   }

  //   return this.localUser.menuIds.includes(requiredMenuId);
  // }

  private decodeToken(token: string): DecodeToken {
    const payloadBase64 = token.split('.')[1];
    const payload = JSON.parse(atob(payloadBase64));
    return payload;
  }

  getAllMenus(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/AppMenu/`);
  }

  //#region  new Validate Token
  // Validate token API call
  validateToken(token: string): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/api/User/ValidateToken`, { token })
      .pipe(
        tap((response) => {
          if (response.success) {
            this.setTokenValidated(true); // Set validated flag on success
          }
        })
      );
  }

  // Set token validation flag
  setTokenValidated(isValid: boolean): void {
    this.tokenValidated = isValid;
    // You can also store this in sessionStorage or localStorage if needed
    sessionStorage.setItem('tokenValidated', JSON.stringify(isValid)); // Persist across page refresh
  }

  // Get the token validation status
  // unused
  isTokenValidated(): boolean {
    const token = localStorage.getItem('auth_app_token');
    return !!token; // Returns true if token exists, false otherwise
    /*
    const validated = sessionStorage.getItem('auth_app_token');
    return validated ? JSON.parse(validated) : this.tokenValidated;
    */
  }

  // unused
  isTokenValid(token: string): Observable<boolean> {
    if (!token) {
      return of(false); // No token provided, so it's invalid
    }

    // API call to validate the token
    return this.http
      .post(`${this.baseUrl}/api/User/ValidateToken`, { token })
      .pipe(
        map((response: any) => response.isSuccess), // Assume API returns { isValid: true/false }
        catchError((error) => {
          console.error('Token validation failed:', error);
          return of(false);
        }) // Handle errors by considering token invalid
      );
  }

  getTokenExpiry(token: string): number | null {
    try {
      const payload = JSON.parse(atob(token.split('.')[1])); // Decode the JWT payload
      return payload.exp ? payload.exp * 1000 : null; // Convert expiry to milliseconds
    } catch (error) {
      console.error('Invalid token format', error);
      return null;
    }
  }
  //#endregion
}
